import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import formatTitle from "../../components/common/formatTitle";
import { Button } from "gatsby-material-ui-components";
import ArrowButton from "../../components/common/ArrowButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";
import TitreTexteCta from "../../components/common/TitreTexteCta";
import PeerReviewdPaper from "../../components/common/PeerReviewedPaper";
import Heading from "./heading";
import Illustration from "./illustration.svg";
import Dell from "./Dell.png";
import Adlink from "./Adlink.png";
import Huawei from "./Huawei.png";
import Hp from "./Hp.png";
import Nvidia from "./Nvidia.png";
import Aws from "./Aws.png";
import Ibm from "./Ibm.png";
import A from "./anonymisation.svg";
import P from "./Platematching.svg";
import Q from "./Quality.svg";
import F from "./Face.svg";
import C from "./Classification.svg";
import Sh from "./shape.svg";
import Se from "./Segmentation.svg";
import T from "./tracking.svg";
import H1 from "./heading1.png";
import H2 from "./heading2.jpg";
import H3 from "./heading3.jpg";
import FutureG from "./future_gauche.png";
import FutureD from "./future_droite.png";
import randomKey from "../../helpers/randomKey";
const images = [T, Se, Sh, C, F, Q, P, A];

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularLastRow: {
    padding: theme.layout.paddingLastRow,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  lessPaddingBlock1: {
    paddingBottom: "2%",
  },
  centered: {
    textAlign: "center",
    margin: 0,
  },
  skillParag: {
    textAlign: "center",
    maxWidth: 700,
    margin: 0,
  },
  title: {
    margin: 0,
    marginTop: theme.spacing(4),
  },
  cta: {
    marginTop: theme.spacing(3),
  },
  title2: {
    maxWidth: "60%",
    textAlign: "center",
    margin: "auto",
  },
  trusted: {
    marginTop: theme.spacing(6),
    fontSize: "1.4em",
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
  },
  aiCard: {
    boxShadow: "10px 20px 20px 0px rgb(67 67 68 / 11%)", //0px 60px 50px rgba(67,67,68, 0.109804);",
    padding: theme.spacing(2),
    border: "solid 2px #CCD4E6",
    borderRadius: 5,
  },
  aiCardTitle: {
    fontSize: "1.2em",
    fontWeight: 1000,
    textAlign: "center",
    margin: `${theme.spacing(1)}px 0`,
    minHeight: 52,
  },
  aiCardImg: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  aiCardContent: {
    textAlign: "center",
    minHeight: 120,
  },
  secondRowMargin: {
    marginTop: theme.spacing(4),
  },
  relative: {
    position: "relative",
  },
  whiteBg: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "#FFF",
    width: "100%",
    height: "30%",
    zIndex: 700,
  },
  Illustration: {
    zIndex: 800,
  },
  partner: {
    width: 140,
    height: "auto",
  },
  lessPadding: {
    paddingBottom: 0,
  },
  decoBox1: {
    width: 12,
    height: 12,
    left: "20%",
    top: "15%",
    backgroundColor: theme.palette.primary.light,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox2: {
    width: 20,
    height: 20,
    left: "9%",
    top: "40%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox3: {
    width: 20,
    height: 20,
    left: "65%",
    top: "9%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox4: {
    width: 20,
    height: 20,
    right: "5%",
    top: "40%",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox5: {
    width: 12,
    height: 12,
    left: "80%",
    top: "55%",
    backgroundColor: theme.palette.primary.light,
    position: "absolute",
    borderRadius: 2,
  },
  decoBox6: {
    width: 12,
    height: 12,
    left: "30%",
    top: "85%",
    backgroundColor: theme.palette.primary.light,
    position: "absolute",
    borderRadius: 2,
  },
  future: {
    backgroundColor: "#eaeff6",
    backgroundImage: `url(${FutureG}), url(${FutureD})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%, auto 100%",
    backgroundPosition: "0 100%, 100% 100%",
  },
}));

const Skills = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const contents = data.allSkillsJson.nodes[0].skills[langKey][0];
  const papers =
    data.allPublicationsJson.nodes[0].publications[langKey][0].papers;
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item container direction="column" alignItems="stretch">
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.lessPaddingBlock1]: matches,
            [classes.paddingRegularMobile]: !matches,
            [classes.relative]: true,
          })}
          spacing={4}
        >
          {matches &&
            [1, 2, 3, 4, 5, 6].map((idx) => (
              <div
                key={`decoBox-${idx}`}
                className={classes[`decoBox${idx}`]}
              />
            ))}
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              {formatTitle(contents.cutting.title)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.skillParag)}
            >
              {contents.cutting.content}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={matches ? 8 : 2}
            justify="center"
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                to={`/${langKey}/about`}
                className={clsx({ [classes.cta]: matches })}
              >
                {contents.cutting.btnLabel1}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                to={`/${langKey}/publications`}
                className={clsx({ [classes.cta]: matches })}
              >
                {contents.cutting.btnLabel2}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={clsx(classes.centered, classes.withBg, classes.relative, {
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
          spacing={matches ? 4 : 0}
        >
          {matches && <div id="whiteBg" className={classes.whiteBg} />}
          {matches && (
            <Grid item className={classes.Illustration}>
              <img src={Illustration} alt="test" />
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.trusted}
            >
              {contents.partners.title}:
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={!matches ? "column" : "row"}
            justify="center"
          >
            <Grid item>
              <img src={Ibm} alt="ibm" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Dell} alt="dell" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Adlink} alt="adlink" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Aws} alt="Aws" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Huawei} alt="huawei" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Hp} alt="text" className={classes.partner} />
            </Grid>
            <Grid item>
              <img src={Nvidia} alt="nvidia" className={classes.partner} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          spacing={6}
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
        >
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              {formatTitle(contents.functions.title)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.skillParag)}
            >
              {contents.functions.content}
            </Typography>
          </Grid>
          <Grid item container direction="row" spacing={4}>
            {contents.functions.items.map((f, idx) => (
              <Grid
                item
                xs={12}
                sm={3}
                key={`contents-row-${idx}-${randomKey()}`}
              >
                <div
                  className={clsx(classes.aiCard, {
                    [classes.secondRowMargin]: matches && idx > 3,
                  })}
                >
                  <div className={classes.aiCardImg}>
                    <img
                      src={images[idx]}
                      alt={F.title}
                      width="64px"
                      height="auto"
                    />
                  </div>
                  <Typography
                    varitant="h6"
                    className={classes.aiCardTitle}
                    color="textSecondary"
                  >
                    {f.title}
                  </Typography>
                  <Typography
                    varitant="body1"
                    color="textSecondary"
                    className={classes.aiCardContent}
                  >
                    {f.content}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Heading
          mainTitle={contents.headlines[0].mainTitle}
          content1={contents.headlines[0].content1}
          title1={contents.headlines[0].title1}
          content2={contents.headlines[0].content2}
          title2={contents.headlines[0].title2}
          src={H1}
          reverse
          matches
        />
        <Heading
          mainTitle={contents.headlines[1].mainTitle}
          content1={contents.headlines[1].content1}
          title1={contents.headlines[1].title1}
          content2={contents.headlines[1].content2}
          title2={contents.headlines[1].title2}
          src={H2}
          illustrationInverted
          illustrationPosition="R"
          matches
        />
        <Heading
          mainTitle={contents.headlines[2].mainTitle}
          content1={contents.headlines[2].content1}
          title1={contents.headlines[2].title1}
          content2={contents.headlines[2].content2}
          title2={contents.headlines[2].title2}
          src={H3}
          reverse
          matches
        />
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
            [classes.lessPadding]: matches,
          })}
          spacing={4}
        >
          <Grid item>
            <Typography variant="h1" className={classes.title2}>
              {formatTitle(contents.expertise.title)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.skillParag)}
            >
              {contents.expertise.content}
            </Typography>
          </Grid>
          <Grid item>
            <ArrowButton
              label={contents.expertise.btnLabel}
              to={`/${langKey}/publications`}
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
        >
          {papers.slice(0, 3).map((p) => (
            <PeerReviewdPaper
              key={`papers-${p}-${randomKey()}`}
              {...p}
              langKey={langKey}
            />
          ))}
        </Grid>
        {matches && (
          <Grid
            item
            container
            direction="row"
            spacing={2}
            className={clsx(classes.future, {
              [classes.paddingRegular]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            <TitreTexteCta
              title={contents.letsBuild.title}
              buttonLabel={contents.letsBuild.btnLabel}
              text={contents.letsBuild.content}
              ctaUrl={`/${langKey}/contact`}
              lg={8}
              md={8}
              sm={8}
              darkTheme
            />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default Skills;

export const query = graphql`
  query SkillsQueryFr {
    allPublicationsJson {
      nodes {
        publications {
          fr {
            papers {
              acceptation
              authors
              content
              date
              object
              url
            }
          }
        }
      }
    }
    allSkillsJson {
      nodes {
        skills {
          fr {
            cutting {
              btnLabel1
              btnLabel2
              content
              title
            }
            partners {
              title
            }
            functions {
              content
              items {
                content
                title
              }
              title
            }
            headlines {
              content1
              content2
              mainTitle
              title1
              title2
            }
            expertise {
              title
              content
              btnLabel
            }
            letsBuild {
              title
              content
              btnLabel
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
